import React, { useState } from 'react';
import { Box, Grid, Text, Badge, Button, Link, HStack, Spacer, Stack, ButtonGroup, IconButton } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { StateText, SubmissionsWithGClassWork } from '../../models/gclass';
import { States } from 'protobuffer-ts/dist/class_service/gclass';
import { formatDistance } from 'date-fns';
import { findTurnedInOrReturnedHistory } from '../../utils/GClass';
import { FaList, FaThLarge } from 'react-icons/fa';
import { FlexWrap } from '../layout/FlexWrap';

interface SubmissionListProps {
    submissions: SubmissionsWithGClassWork[];
}


const getSubmissionColors = (submission: SubmissionsWithGClassWork) => {
    const { workNotSubmittedAndLate, submissionState, due_date } = submission;

    if (workNotSubmittedAndLate) return {
        color: 'red.500'
    };
    
    // In progress means that the work is not turned in and not returned
    const isInprogress = ![States.STATES_TURNED_IN, States.STATES_RETURNED].includes(submissionState);

    if (isInprogress) return {
        color: 'blue.500',
    };

    const turnedInHistory = findTurnedInOrReturnedHistory(submission);

    if (!turnedInHistory) return {
        color: 'gray.500',
    };

    const turnedInDate = new Date(turnedInHistory.stateHistory?.stateTimestamp || '');
    const dueDateObj = new Date(due_date || '');

    if (turnedInDate > dueDateObj) return {
        color: 'yellow.500',
    };

    return {
        color: 'green.500',
    };
};

const SubmissionCard: React.FC<{ submission: SubmissionsWithGClassWork, type?: 'row' | 'card' }> = ({ submission, type = 'row' }) => {
    const { color } = getSubmissionColors(submission);

    // Get the turnedInHistory to display the correct date
    const turnedInHistory = findTurnedInOrReturnedHistory(submission);
    const turnedInDate = turnedInHistory ? new Date(turnedInHistory.stateHistory?.stateTimestamp || '') : null;
    const submissionDate = turnedInDate ? turnedInDate.toLocaleDateString() : 'N/A';

    const dueDateObj = new Date(submission.due_date || '');
    let lateLabel = '';

    if (turnedInDate && turnedInDate > dueDateObj) {
        // Calculate the relative time difference using date-fns
        const distance = formatDistance(dueDateObj, turnedInDate, { addSuffix: false });

        lateLabel = `(Submitted ${distance} late)`;
    }

    return (
        <Box
            as={Stack}
            bg="white"
            p={4}
            borderRadius="md"
            border={`1px dashed currentColor`}
            borderColor={color}
        >
            <HStack flexWrap={'wrap'} gap={2} mb={type === 'card' ? 2 : 0} alignItems="flex-start">
                <Text fontWeight={"bold"} color={"gray.700"}>
                    {submission.title}
                </Text>

                {type === 'card' && <Spacer />}

                <Badge bg={color} color="white" rounded="md" style={{ marginInlineStart: '0' }}>
                    {submission.workNotSubmittedAndLate ? 'Not Submitted & Late' : StateText(submission.submissionState)}
                </Badge>

                {type === 'row' && (
                    <Spacer flexGrow={1} />
                )}

                {type === 'row' && submission.gclassLink && (
                    <Link href={submission.gclassLink} isExternal>
                        <Button mt={2} variant='link' colorScheme="blue" leftIcon={<ExternalLinkIcon />}>
                            View Submission
                        </Button>
                    </Link>
                )}
            </HStack>

            {
                submission.student_name && (
                <Text fontWeight={"semibold"} color={"gray.700"}>
                    Student: {submission.student_name}
                </Text>
                )
            }

            {
                submission.due_date && (
                <Text fontWeight={"semibold"} color={"gray.700"}>
                    Due Date: {new Date(submission.due_date).toLocaleDateString()}
                </Text>
                )
            }

            <FlexWrap justify="flex-start">
                <Text whiteSpace="nowrap" fontWeight={"semibold"} color={"gray.700"}>
                    Submitted at: {submissionDate}
                </Text>

                <Badge rounded="md" bgColor="yellow.50" textColor="yellow.600">
                    {lateLabel}
                </Badge>
            </FlexWrap>

            {type === 'card' && submission.gclassLink && (
                <Link href={submission.gclassLink} isExternal alignItems="flex-end" flexGrow={1}>
                    <Button mt={2} variant='link' colorScheme="blue" leftIcon={<ExternalLinkIcon />}>
                        View Submission
                    </Button>
                </Link>
            )}
        </Box>
    );
};

const SubmissionList: React.FC<SubmissionListProps> = ({ submissions }) => {
    const [viewType, setViewType] = useState<'card' | 'row'>('card');

    return (
        <>
            <HStack justifyContent="space-between" mb={4}>
                <Text fontSize="xl" fontWeight="bold">Submissions List</Text>

                {
                    submissions.length && 
                    <ButtonGroup size="md" isAttached variant="solid">
                        <IconButton
                            icon={<FaThLarge />}
                            aria-label="Cards View"
                            onClick={() => setViewType('card')}
                            colorScheme={viewType === 'card' ? 'blue' : 'gray'}
                        />
                        <IconButton
                            icon={<FaList />}
                            aria-label="Rows View"
                            onClick={() => setViewType('row')}
                            colorScheme={viewType === 'row' ? 'blue' : 'gray'}
                        />
                    </ButtonGroup>
                }
                
            </HStack>

            <Grid
                templateColumns={
                    viewType === 'card' 
                        ? { base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }
                        : { base: "1fr" }
                }
                gap={4}
                mb={8}
            >
                {submissions.map((submission) => (
                    <SubmissionCard 
                        key={submission.gclassStudentSubmissionId} 
                        submission={submission} 
                        type={viewType}
                    />
                ))}
            </Grid>
        </>
    );
};

export default SubmissionList;