import * as yup from "yup";
import { ReviewStatus, ReportType } from "../models/report_entry";
import { ObjectId } from "../utils/ObjectId";

const reportTypes = Object.values(ReportType)
const reviewStatus = Object.values(ReviewStatus)

export const updateReportEntrySchema = yup.object().shape({
    _id: yup.mixed<ObjectId>().label("Report Entry Id").required(),
    organization: yup.mixed<ObjectId>().label("Organization Id").required(),
    student: yup.mixed<ObjectId>().label("Student Id").required(),
    course: yup.mixed<ObjectId>().label("Course Id").required(),

    comment: yup.string().label("Comment").required(),
    requested_changes: yup.string().label("Requested Changes").defined().strict(true),
    report_type: yup.string().label("Report Type").oneOf(reportTypes).required(),
    review_status: yup.string().label("Report Status").oneOf(reviewStatus).required(),

    sections: yup.array().label("Sections").of(
        yup.object().shape({
            layout_id: yup.mixed<ObjectId>().label("Layout Id").required(),
            mark: yup.string().label("Mark").required(),
        })
    ).required(),

    checkboxes: yup.array().of(
        yup.object().shape({
            check_box_id: yup.mixed<ObjectId>().label("Checkbox Id").required(),
            state: yup.bool().required(),
        })
    ).required(),

    learning_skills: yup.array().of(
        yup.object().shape({
            learning_skill_id: yup.mixed<ObjectId>().label("Learning Skill Id").required(),
            mark: yup.string().label("Mark").required(),
        })
    ).required(),
});

