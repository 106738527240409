import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { Homeroom } from "protobuffer-ts/dist/class_service/homeroom";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { GClassCourseWork } from "protobuffer-ts/dist/class_service/gclass";
import { Semester } from "protobuffer-ts/dist/class_service/semester";
import { CourseCreate } from "../models/course";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { updateReportLayout } from "../models/report_layout";

export namespace CourseAPI {
    export interface ListCourse {
        _id: ObjectId,
        archived: boolean,
        name: string,
        course_code: string,
        semester: Semester
        homeroom?: Homeroom
        teachers: Array<ObjectId>,
    }
    export interface CourseList {
        courses: Array<ListCourse>,
        courses_count: number
    }
    export const course_list = async (
        per_page: number | null = null, 
        page: number | null = null, 
        name_search: string | undefined = undefined, 
        archived: boolean | undefined = undefined,
        teacher_id: ObjectId | undefined = undefined,
        attendance_teacher_id: ObjectId | undefined = undefined,
        hide_homeroom_courses: boolean | undefined = undefined,
        semester_id: ObjectId | undefined = undefined,
        ): Promise<CourseList> => {
        return HTTPApi.post(
                "courses/courses_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                    semester: semester_id,
                    archived: archived,
                    teacher_id: teacher_id,
                    hide_homeroom_courses: hide_homeroom_courses,
                    attendance_teacher_id: attendance_teacher_id,
                })
                .then((res) => {
                    return res.data as CourseList
                })
                .catch((e) => {
                    throw e
                })
    }

    export const get_course = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get/" + course_id.$oid)
                .then((res) => {
                    return Course.create(res.data)
                })
    }

    export const get_report_layout = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_course_report_layout/" + course_id.$oid)
            .then((res) => {
                return res.data as ReportLayout
            })
    }

    export const archive_course = async (course_id: ObjectId) => {
        return HTTPApi.post(
            "courses/archive", {
                _id: course_id,
            }
        )
    }

    export const create = async (body : CourseCreate) => {
        return HTTPApi.post(
            "courses/create", body
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    export const homeroom_create = async (body : CourseCreate) => {
        return HTTPApi.post(
            "courses/homeroom/create", body
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    // export const clone = async (body : CourseClone) => {
    //     return HTTPApi.post(
    //         "courses/clone", body
    //     ).then((res) => {
    //         return Course.fromJSON(res.data)
    //     })
    // }

    export const update = async (courseId: ObjectId, semester_id: ObjectId, name: string, course_code: string) => {
        return HTTPApi.post(
            "courses/update", 
            {
                _id: courseId, 
                name: name,
                semester_id: semester_id,
                course_code: course_code,
            }
        ).then((res) => {
            return Course.fromJSON(res.data);
        });
    };

    export const add_teachers = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_teachers", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    export const remove_teachers = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/remove_teachers", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    export const add_students = async (course_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_students", {
                _id: course_id,
                students: students
            }
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    export const remove_students = async (course_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/remove_students", {
                _id: course_id,
                students: students
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    export const get_gclass_url = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_gclass_url/" + course_id.$oid)
                .then((res) => {
                    return res.data as string
                })
    }

    export const get_gclass_work = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_gclass_work/" + course_id.$oid)
                .then((res) => {
                    return res.data.map((e: any) => GClassCourseWork.fromJSON(e));
                })
    }

    export const add_attendance_teacher = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_attendance_teacher", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return Course.fromJSON(res.data)
        })
    }

    export const update_report_layout = async (data: updateReportLayout) => {
        return HTTPApi.post("courses/update_report_layout", data)
        .then((res) => {
            return res.data as ReportLayout;
        });
    };

}