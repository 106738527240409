import React from 'react';
import { FormControl, FormLabel, Textarea, FormErrorMessage, TextareaProps } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './styles/form-input.css';

interface FormTextareaProps extends TextareaProps {
    name: string;
    label?: string;
    register: UseFormRegisterReturn;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
}

const FormTextarea: React.FC<FormTextareaProps> = ({ name, label, placeholder, register, error, isDisabled = false, isRequired= false, ...props }) => {
    return (
        <FormControl isInvalid={!!error} >
            {label && (
                <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>
                    {label}
                </FormLabel>
            )}

            <Textarea
                 {...props}
                 {...register} 
				id={name}
                isDisabled={isDisabled}
                placeholder={placeholder}
            />
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormTextarea;
