import React, { useEffect, useState } from "react";
import {
    Text,
    Stack,
    HStack,
    FormLabel,
    SimpleGrid,
    useToast,
    Alert,
    Box,
    AlertTitle,
    AlertDescription,
    Badge,
    Spacer,
    Button,
} from '@chakra-ui/react';
import { ReportAPI } from "../../api/ReportAPI";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { ReportEntry, ReviewStatus, ReportType, ReviewStatusColor } from "../../models/report_entry";
import { to_oid, ObjectId } from "../../utils/ObjectId";
import { Student } from "../../models/student";
import { StudentAPI } from "../../api/StudentAPI";
import { BlCard } from "../layout/Card";
import { FiAlertCircle } from "react-icons/fi";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { updateReportEntrySchema } from "../../validations/report_entry";
import FormSwitch from "../forms/FormSwitch";
import FormInput from "../forms/FormInput";
import { organizationId } from "../../utils/organization";
import FormTextarea from "../forms/FormTextarea";
import LocalStorageService from "../../api/LocalStorageService";
import { UserRole } from "../../api/UserApi";
import { FlexWrap } from "../layout/FlexWrap";
import { convertCaseToNormal } from "../../utils/helpers";
import { LightButton } from "../layout/LightButton";
import { HiOutlineDocumentAdd, HiPencil } from "react-icons/hi";
import { RequestChangesDialog } from "./RequestChangesDialog";
import { CheckIcon } from "@chakra-ui/icons";

interface Props {
	report_id: ObjectId,
	student_id: ObjectId
	course: Course
	report_type: ReportType
	report_layout: ReportLayout

}

export const StudentReportEntry : React.FC<Props> = ({report_id, student_id, course, report_type, report_layout}) => {
    const toast = useToast();

	const [student, set_student] = useState<Student>();
    const [report_entry, set_report_entry] = useState<ReportEntry>();

    const user = LocalStorageService.getInstance().getUser();
    const canApprove = user?.has_role(UserRole.Can_Approve_ReportEntry);

    const [isOpen_request_changes, setIsOpen_request_changes] = useState(false)


    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({
        resolver: yupResolver(updateReportEntrySchema),
    });

    useFieldArray({ control, name: "sections" });
    useFieldArray({ control, name: "checkboxes" });
    useFieldArray({ control, name: "learning_skills" });
    
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const res = await StudentAPI.get_student(student_id.$oid)
    
                set_student(res!);
    
                try {
                    const reportEntryRes = await ReportAPI.get_report_entry(report_id.$oid);
                    set_report_entry(reportEntryRes);
                    reset(reportEntryRes);
                } catch (error) {
                    // If the report entry does not exist, create an empty one
                    const reportEntry: ReportEntry = {
                        _id: report_id,
                        organization: organizationId,
                        course: to_oid(course.id!.id),
                        student: student!._id,

                        report_type,
                        review_status: ReviewStatus.Filled,
                        comment: "",
                        requested_changes: "",

                        checkboxes: report_layout?.checkBoxes?.map(checkbox => ({
                            check_box_id: to_oid(checkbox.id!.id),
                            state: false
                        })) || [],

                        sections: report_layout?.sections?.map(section => ({
                            layout_id: to_oid(section.id!.id),
                            mark: ""
                        })) || [],

                        learning_skills: course?.semester?.reportLayout?.learningSkills?.map(learningSkill => ({
                            learning_skill_id: to_oid(learningSkill.id!.id),
                            mark: ""
                        })) || []
                    };

                    reset(reportEntry);
                }
            } catch (e) {
                console.error('Error fetching data', e);
            }
        };
    
        fetchAllData();
    }, [])


    const updateReportEntry = async (data : ReportEntry) => {

        try {
            const res = await ReportAPI.update_report_entry(data);
            updateData(res);
    
            toast({
                title: 'Success',
                description: "Report entry updated successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Failed to update report entry",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } 
    };

    const approve = async () => {

        try {
            const res = await ReportAPI.approve({ report_id });

            updateData(res);
    
            toast({
                title: 'Success',
                description: "Report entry approved successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Failed to approve report entry",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const onCloseRequestchanges = (data? : ReportEntry) => {
        if (data) updateData(data);

        setIsOpen_request_changes(false)
    }
    
    const updateData = (data : ReportEntry) => {
        reset(data)
        set_report_entry(data)
    }

    return (
        <Stack gap={4}                 
            as="form"
            onSubmit={handleSubmit(updateReportEntry)}
        >
			<BlCard>
                <FlexWrap mb="6">
                    <HStack gap="2">
                        <Text fontSize="lg" fontWeight="bold" >
							{student?.profile.first_name} {student?.profile.last_name}
                        </Text>
                        
                        { report_entry?.review_status && 
                            <Badge fontSize='xs' colorScheme={ReviewStatusColor[report_entry.review_status]}>
                                {convertCaseToNormal(report_entry.review_status)}
                            </Badge>
                        }
                    </HStack>

                    <Spacer />
 
                    <FlexWrap>
                        { canApprove && 
                        <>
                            <LightButton 
                                leftIcon={<HiPencil />}
                                color="orange"
                                onClick={() => setIsOpen_request_changes(true)}
                            >
                                Request Changes
                            </LightButton>

                            <RequestChangesDialog
                                report_id={report_id}
                                isOpen={isOpen_request_changes} 
                                onClose={(report_entry) => onCloseRequestchanges(report_entry)}
                            />

                        </>
                        }
                        
                        { canApprove && 
                            <Button 
                                leftIcon={<CheckIcon/>}
                                colorScheme="blue"
                                onClick={approve}
                            >
                                Approve
                            </Button>
                        }

                        <Button 
                            colorScheme="green" 
                            leftIcon={<HiOutlineDocumentAdd />}
                            type="submit" 
                        >
                            Update
                        </Button>
                    </FlexWrap>
                </FlexWrap>

            { report_entry?.requested_changes && 
                <Alert borderRadius="lg" bgColor="orange.100" gap="4" mb="8">
                    <FiAlertCircle color="orange" size="50" />

                    <Box>
                        <AlertTitle color="orange.600">Changes Requested!</AlertTitle>

                        <AlertDescription fontWeight="bold">
                            {report_entry.requested_changes}
                        </AlertDescription>
                    </Box>
                </Alert>
            }

				<SimpleGrid
					bg="gray.50"
                    border="1px dashed"
                    borderColor="gray.300"
					p="4"
					rounded="md"
					columns={{md: 2, lg: 4 }} gap="6"
				>
					<Stack gap="4">
						<Text fontWeight="bold">Checkboxes</Text>

						{report_layout?.checkBoxes?.map((checkbox, index) => (
							<HStack key={checkbox.id?.id}>
								<FormSwitch 
									name={`checkboxes.${index}.state`}
									control={control}
								/>

								<FormLabel> {checkbox.name} </FormLabel>
							</HStack>
						))}
					</Stack>

					<Stack gap="4">
						<Text fontWeight="bold">Marks</Text>

						{report_layout?.sections?.map((section, index) => (
							<FormInput
								label={section.name}
								name={`sections.${index}.mark`}
								register={register(`sections.${index}.mark`)}
								error={errors?.sections?.[index]?.mark?.message}
								placeholder="enter mark..."
							/>
						))}

					</Stack>

					<Stack gridColumn={"3/5"} gap="4">
						<Text fontWeight="bold">Learning Skills</Text>

						{course?.semester?.reportLayout?.learningSkills?.map((learningSkill, index) => (
							<HStack key={learningSkill.id?.id}>
								<FormInput
									label={learningSkill.title}
									name={`learning_skills.${index}.mark`}
									register={register(`learning_skills.${index}.mark`)}
									error={errors?.learning_skills?.[index]?.mark?.message}
									placeholder="enter mark..."
								/>
							</HStack>
						))}
					</Stack>
				</SimpleGrid>

				<Box>
                    <Text fontWeight="bold" mb="2">Comment</Text>

                    <FormTextarea
                        rows={5}
                        name="comment"
                        register={register("comment")}
                        error={errors?.comment?.message}
                        placeholder="enter Comment..."
                    />
                </Box>

			</BlCard>

        </Stack>
    );
};
