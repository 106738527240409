import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, useToast, Text, IconButton, HStack, Collapse, Spacer, Alert, AlertIcon, Icon } from "@chakra-ui/react";
import { updateReportLayoutSchema } from "../../../validations/report_layout";
import { updateReportLayout } from "../../../models/report_layout";
import { CourseAPI } from "../../../api/CourseAPI";
import { new_oid, new_oid_proto } from "../../../utils/ObjectId";
import FormInput from "../../forms/FormInput";
import { AddIcon, MinusIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon } from "@chakra-ui/icons";
import { DeleteDialog } from "../../dialog/DeleteDialog";
import { LightButton } from "../../layout/LightButton";
import { BlCard } from "../../layout/Card";
import { SemesterReportLayout } from "protobuffer-ts/dist/class_service/semester";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { proto_utils } from "../../../utils/proto_utils";

interface ReportLayoutFormProps {
    reportLayout?: ReportLayout;
    semesterReportLayout?: SemesterReportLayout
    onSubmitSuccess: (updatedLayout: ReportLayout) => void;
    hideUpdateButton?: boolean;
}

const ReportLayoutForm: React.FC<ReportLayoutFormProps> = ({ reportLayout, semesterReportLayout, onSubmitSuccess, hideUpdateButton = false }) => {
    const toast = useToast();
    const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm({
        resolver: yupResolver(updateReportLayoutSchema),
    });

    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);
    const [isCheckboxOpen, setIsCheckboxOpen] = useState<boolean>(true);
    const [isCheckboxAlertOpen, setIsCheckboxAlertOpen] = useState<boolean>(false);
    const [isSectionAlertOpen, setIsSectionAlertOpen] = useState<boolean>(false);
    const [sectionRemoveIndex, setSectionRemoveIndex] = useState<{ section?: number}>({});
    const [checkboxRemoveIndex, setCheckboxRemoveIndex] = useState<{ checkbox?: number}>({});

    // Handle dynamic sections
    const { fields: sectionFields, append: appendSection, remove: removeSection } = useFieldArray({
        control,
        name: "sections",
    });

    // Handle dynamic checkboxes
    const { fields: CheckBoxFields, append: appendCheckbox, remove: removeCheckbox } = useFieldArray({
        control,
        name: "check_boxes",
    });

    // Update the form with passed-in reportLayout
    useEffect(() => {
        if (reportLayout) {
            // TODO: CHEECK WHY ID IS OPTIONAL IN CHECKBOXES AND SECITONS
            let report_layout: updateReportLayout = {
                _id: proto_utils.from_proto_object_id(reportLayout.id!),
                comment_char_limit: reportLayout.commentCharLimit || 800,
                check_boxes: reportLayout.checkBoxes?.map((checkbox => ({ ...checkbox, id: checkbox.id! }))) || [],
                sections: reportLayout.sections?.map((section => ({ ...section, id: section.id! }))) || [],
            }

            reset(report_layout);
        }
    }, [reportLayout, reset]);

    const toggleSections = () => {
        setIsSectionOpen(!isSectionOpen );
    };

    const toggleCheckBoxes = () => {
        setIsCheckboxOpen(!isCheckboxOpen);
    };

    const onSubmit = async (data: updateReportLayout) => {
        try {
            const updatedLayout = await CourseAPI.update_report_layout(data)
            onSubmitSuccess(updatedLayout);

            toast({
                title: "Success",
                description: "Report layout updated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update report layout",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleRemoveSection = (index: number) => {
        setSectionRemoveIndex({ section: index });
        setIsSectionAlertOpen(true);
    };

    const handleRemoveCheckbox = ( checkboxIndex: number) => {
        setCheckboxRemoveIndex({checkbox: checkboxIndex });
        setIsCheckboxAlertOpen(true);
    };

    const confirmCheckboxRemove = () => {
        removeCheckbox(checkboxRemoveIndex.checkbox);
        setIsCheckboxAlertOpen(false);
    };

    const confirmSectionRemove = () => {
        removeSection(sectionRemoveIndex.section);
        setIsSectionAlertOpen(false);
    };

    return (
        <BlCard
            as="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack gap="4">
                <HStack>
                    <Text fontSize={"lg"} fontWeight="bold">
                        Report Layout
                    </Text>

                    <Spacer />

                    <LightButton
                        leftIcon={<CheckIcon/>}
                        type="submit" 
                        color="green" 
                        isLoading={false} 
                        hidden={hideUpdateButton}
                        mt={4} 
                    >
                        Update Report Layout
                    </LightButton>
                </HStack>

                <FormInput
                    name="comment_char_limit"
                    label="Comment Character Limit"
                    register={register("comment_char_limit")}
                    error={errors.comment_char_limit?.message}
                    isRequired
                    
                />

                {
                    !Number(watch('comment_char_limit')) && (
                        <Box
                            as={HStack} 
                            gap="2"
                            p="4"
                            bgColor="blue.50"
                            textColor="blue.600"
                            rounded="md"
                            mb="4"
                        >
                            <Icon as={FiAlertCircle} boxSize="8" />
        
                            <Text fontWeight={500}>
                                When Comment Character Limit is set to 0, report layout will use the limit from Semester Report Layout which is set to {semesterReportLayout?.commentCharLimit}
                            </Text>
                        </Box>
                    )
                }
    
                <HStack>
                    <Text fontSize={"md"} fontWeight="bold">
                        Checkboxes
                    </Text>

                    <Spacer />

                    <IconButton
                        aria-label="Toggle Sections"
                        icon={isCheckboxOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        onClick={toggleCheckBoxes}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
                <Collapse in={isCheckboxOpen}>
                <Box p="4" borderWidth="1px" borderRadius="lg">
                        <Stack gap="4">
                            {CheckBoxFields.map((checkbox, index) => (
                                <HStack key={checkbox.id}>
                                    <FormInput
                                        label={''}
                                        name={`check_boxes.${index}.name`}
                                        register={register(`check_boxes.${index}.name` as const)}
                                        error={errors.check_boxes?.[index]?.name?.message}
                                    />

                                    <IconButton
                                        aria-label="Remove Checkbox"
                                        icon={<MinusIcon />}
                                        variant="outline"
                                        borderColor="red.600"
                                        textColor="red.600"
                                        size="sm"
                                        onClick={() => handleRemoveCheckbox( index)}
                                    />
                                </HStack>
                            ))}
                            <Button
                                aria-label="Add Checkbox"
                                leftIcon={<AddIcon />}
                                variant="outline"
                                border="1px dashed"
                                borderColor="gray.400"
                                size="sm"
                                alignSelf="start"
                                onClick={() => appendCheckbox({ id: new_oid_proto(), name: '' })}
                            >
                                add checkbox
                            </Button>
                        </Stack>
                    </Box>
                </Collapse>
                <HStack>
                    <Text fontSize={"md"} fontWeight="bold">
                        Sections
                    </Text>

                    <Spacer />

                    <IconButton
                        aria-label="Toggle Sections"
                        icon={isSectionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        onClick={toggleSections}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
                <Collapse in={isSectionOpen}>
                {/* Sections */}
                {sectionFields.map((section, index) => (
                    <Box key={section.id} p="1"  borderRadius="sm" mb={1}>
                        <HStack alignItems="center" borderRadius="sm">
                            <FormInput
                                name={`sections.${index}.name` as const}
                                label={``}
                                register={register(`sections.${index}.name` as const)}
                                error={errors.sections?.[index]?.name?.message}
                                isRequired
                                isHeader={true}
                            />
                            <IconButton
                                aria-label="Remove Section"
                                icon={<MinusIcon />}
                                variant="outline"
                                borderColor="red.600"
                                textColor="red.600"
                                size="sm"
                                onClick={() => handleRemoveSection(index)}
                            />
                        </HStack>
                    </Box>

                ))}
                </Collapse>
                {/* Add Section Button */}
                <HStack justifyContent="flex-start" mt={4}>
                    <Button
                        aria-label="Add Section"
                        leftIcon={<AddIcon />}
                        variant="outline"
                        border="1px dashed"
                        borderColor="gray.400"
                        size="sm"
                        onClick={() => appendSection({ id: new_oid_proto(), name: '' })}
                    >
                        Add Section
                    </Button>
                </HStack>

            </Stack>

            <DeleteDialog
                item="section"
                isOpen={isSectionAlertOpen}
                action={confirmSectionRemove}
                onClose={() => setIsSectionAlertOpen(false)}
            />

            <DeleteDialog
                item="checkbox"
                isOpen={isCheckboxAlertOpen}
                action={confirmCheckboxRemove}
                onClose={() => setIsCheckboxAlertOpen(false)}
            />
        </BlCard>
    );
};

export default ReportLayoutForm;
