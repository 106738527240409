import { ObjectId } from "../utils/ObjectId"

export enum ReportType {
    Progress = "Progress",
    Midterm = "Midterm",
    Final = "Final"
}

export const reportTypeOptions = Object.values(ReportType).map((type) => ({
    value: type,
    label: type,
}));

export enum ReviewStatus {
    NotFilled = "NotFilled",
    Filled = "Filled",
    Viewed = "Viewed",
    ChangesRequested = "ChangesRequested",
    Completed = "Completed",
    Approved = "Approved"
}

export const reviewStatusOptions = Object.values(ReviewStatus).map((status) => ({
    value: status,
    label: status,
}));

export interface CheckBox {
    check_box_id: ObjectId,
    state: boolean,
}

interface Section {
    layout_id: ObjectId,
    mark: string,
}

interface LeaningSkill { 
    learning_skill_id: ObjectId,
    mark: string,
}

export interface ReportEntry {
    _id: ObjectId
    organization: ObjectId
    student: ObjectId
    course: ObjectId

    report_type: ReportType
    review_status: ReviewStatus
    comment: string
    requested_changes: string
    
    checkboxes: CheckBox[]
    sections: Section[]
    learning_skills: LeaningSkill[]
}

export interface RequestChangesReportEntry {
    report_id: ObjectId,
    requested_changes: String,
}

export const ReportTypeColor: Record<ReportType, string> = {
    [ReportType.Progress]: 'blue',
    [ReportType.Midterm]: 'green',
    [ReportType.Final]: 'purple'
};


export const ReviewStatusColor: Record<ReviewStatus, string> = {
    [ReviewStatus.NotFilled]: 'gray',
    [ReviewStatus.Filled]: 'blue',
    [ReviewStatus.Viewed]: 'lightblue',
    [ReviewStatus.ChangesRequested]: 'orange',
    [ReviewStatus.Completed]: 'purple',
    [ReviewStatus.Approved]: 'green'
};
