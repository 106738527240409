import HTTPApi from "./ApiCommon";
import { ReportEntry, RequestChangesReportEntry } from "../models/report_entry";
import { ObjectId } from "../utils/ObjectId";


export namespace ReportAPI {
    export const get_course_report_entries = async (courseId: string) => {
        return HTTPApi.get(`/reports/get_course_report_entries/${courseId}`)
            .then((res) => {
                return res.data as ReportEntry[]
            })
    }

    export const get_report_entry = async (reportId: string) => {
        return HTTPApi.get(`/reports/get_report_entry/${reportId}`)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const update_report_entry = async (ReportEntry: ReportEntry) => {
        return HTTPApi.post("/reports/update", { report_entry: ReportEntry })
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const request_changes = async (data: RequestChangesReportEntry) => {
        return HTTPApi.post("/reports/request_changes", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }

    export const approve = async (data: { report_id : ObjectId}) => {
        return HTTPApi.post("/reports/approve", data)
            .then((res) => {
                return res.data as ReportEntry
            })
    }
}
