import React, { useEffect, useMemo, useState } from "react";
import {
    Heading,
    Center,
    Stack,
    Text,
    Box,
    FormLabel,
    Flex,
    Icon,
    Badge,
    Divider,
    Button,
    Spacer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
} from '@chakra-ui/react';
import { ReportAPI } from "../../api/ReportAPI";
import { useParams } from "react-router-dom";
import { Course } from "protobuffer-ts/dist/class_service/course";
import { ReportEntry, reportTypeOptions, ReviewStatus, reviewStatusOptions, ReportType, ReportTypeColor } from "../../models/report_entry";
import { CourseAPI } from "../../api/CourseAPI";
import { to_oid } from "../../utils/ObjectId";
import { ReportLayout } from "protobuffer-ts/dist/class_service/report_layout";
import { StudentReportEntry } from "./StudentReportEntry";
import SkeletonTable from "../table/TableSkeleton";
import { BlCard } from "../layout/Card";
import { FlexWrap } from "../layout/FlexWrap";
import { Select } from "chakra-react-select";
import { AiOutlineBook } from "react-icons/ai";
import { HiOutlineCalendar, HiOutlineDocument, HiOutlineFilter } from "react-icons/hi";
import { gerReportDate } from "../../models/semester";


export const ReportDashboard = React.memo(() => {
    let { course_id } = useParams();

    
    const [course, set_course] = useState<Course>();
    const [report_layout, set_report_layout] = useState<ReportLayout>();
    const [report_entries, set_report_entries] = useState<ReportEntry[]>([]);

    const [selected_status, set_selected_status] = useState<ReviewStatus>();
	const [selected_report_type, set_selected_report_type] = useState<ReportType | undefined>(ReportType.Progress);

	const [loading, set_loading] = useState<boolean>(true);

    // Memoized filtered report entries based on the selected filters
    const filtered_entries = useMemo(() => {
        return report_entries.filter((entry) => {
            const matches_review_status = selected_status ? entry.review_status === selected_status : true;
            const matches_report_type = selected_report_type ? entry.report_type === selected_report_type : true;

            console.log(entry, matches_report_type, matches_review_status);

            return matches_review_status && matches_report_type;
        });
    }, [report_entries, selected_status, selected_report_type]);


    useEffect(() => {
        const fetchAllData = async () => {
            try {
                set_loading(true);
    
                const [ courseRes, reportLayoutRes ] = await Promise.all([
                    CourseAPI.get_course(to_oid(course_id!)),
                    CourseAPI.get_report_layout(to_oid(course_id!))
                ]);
    
                set_course(courseRes);
                set_report_layout(reportLayoutRes);

                const entries = await ReportAPI.get_course_report_entries(course_id!)

                set_report_entries(entries)
    
            } catch (e) {
                console.error('Error fetching data', e);
            } finally {
                set_loading(false);
            }
        };
    
        fetchAllData();
    }, [course_id])


    return (
        <Stack gap={4}>
            <Center>
                <Heading size="md" mb="6">
                    Report Entry
                </Heading>
            </Center>

            <BlCard>
                <FlexWrap alignItems="start">
                    <Stack gap="2">
                        <Text fontSize="lg" fontWeight="bold" >
                            Report Information
                        </Text>

                        <Flex gap="8" wrap="wrap">
                            <Flex gap={2} align="center">
                                <Icon as={AiOutlineBook} boxSize={6} color="blue.500" />

                                <Text color="gray.600">Course: <strong>{course?.name}</strong></Text>
                            </Flex>

                            { selected_report_type && 
                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineDocument} boxSize={6} color="purple.500" />
                
                                    <Text color="gray.600">Report Type</Text>

                                    <Badge fontSize='xs' colorScheme={ReportTypeColor[selected_report_type]}>
                                        {selected_report_type}
                                    </Badge>
                                </Flex>
                            }


                            { selected_report_type && 

                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineCalendar} boxSize={6} color="orange.500" />
                
                                    <Text color="gray.600">Due Date</Text>

                                    <Badge fontSize='xs' colorScheme="orange">
                                        {gerReportDate(course?.semester?.reportLayout?.reportDates, selected_report_type, 'dueDate')}
                                    </Badge>
                                </Flex>
                            }

                            { selected_report_type && 
                                <Flex gap={2} align="center">
                                    <Icon as={HiOutlineCalendar} boxSize={6} color="red.500" />
                
                                    <Text color="gray.600">Distribution Date</Text>

                                    <Badge fontSize='xs' colorScheme="red">
                                        {gerReportDate(course?.semester?.reportLayout?.reportDates, selected_report_type, 'distributionDate')}
                                    </Badge>
                                </Flex>
                            }
                        </Flex>
                    </Stack>

                    <Spacer />

                    <Popover placement="bottom-end">
                        <PopoverTrigger>
                            <Button 
                                leftIcon={<HiOutlineFilter/>}
                                color="purple"
                                bgColor="purple.50"
                                _hover={{ bg: "purple.100" }}
                            >
                                Filter                    
                            </Button>
                        </PopoverTrigger>

                        <PopoverContent >
                            <Box
                                as={Stack}
                                bg='bg-surface'
                                boxShadow={useColorModeValue('sm', 'sm-dark')}
                                borderRadius='lg'
                                px={{ base: '4', md: '6' }} 
                                py={{ base: '5', md: '6' }}
                            >
                                <FormLabel mb="0"> Report Type </FormLabel>

                                <Select
                                    isClearable
                                    value={reportTypeOptions.find(option => option.value === selected_report_type)}
                                    options={reportTypeOptions}
                                    onChange={e => set_selected_report_type(e?.value)}
                                    placeholder="Select report type"
                                />

                                <Divider pt="2" />

                                <FormLabel mb="0"> Review Status </FormLabel>

                                <Select	
                                    isClearable
                                    value={reviewStatusOptions.find(option => option.value === selected_status)}
                                    options={reviewStatusOptions}
                                    onChange={e => set_selected_status(e?.value)}
                                    placeholder="Select review status"
                                />
                            </Box>
                        </PopoverContent>
                    </Popover>
                </FlexWrap>
            </BlCard>

            {
                loading ? <SkeletonTable></SkeletonTable> 
                : 
                filtered_entries?.map(report => (
                    <StudentReportEntry
                        key={report._id.$oid}
                        student_id={report.student}
                        course={course!}
                        report_id={report._id}
                        report_layout={report_layout!}
                        report_type={report.report_type}
                    />
                ))
            }
        </Stack>
    )
})
