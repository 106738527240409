import * as yup from 'yup';
import { ReportType } from '../models/report_entry';
import { ObjectId } from 'protobuffer-ts/dist/utils/object_id';

const reportTypes = Object.values(ReportType);  // convet enum to array

const createSemesterSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	start_date: yup.date().required('Start date is required'),
	end_date: yup.date().required('End date is required')
		.min(
			yup.ref('start_date'),
			"End date can't be before start date"
		),
});

const updateSemesterSchema = createSemesterSchema.concat(
	yup.object().shape({
		id: yup.mixed<ObjectId>().required('ID is required'),
		startDate: yup.date().required('Start date is required'),
		endDate: yup.date().required('End date is required')
			.min(
				yup.ref('startDate'),
				"End date can't be before start date"
			),
		reportLayout: yup.object().shape({
			commentCharLimit: yup.number().label("Comment Character Limit").required(),
		
			includeProgressReportCards: yup.boolean().label('Include Progress Report Cards').required(),
			showCreditsEarnedEntry: yup.boolean().label('Show Credits Eearned Entry').required(),
		
			learningSkills: yup.array().of(
				yup.object().shape({
					id: yup.mixed<ObjectId>().label("Section ID").required(),
					title: yup.string().label("Learning Skill title").required(),
					description: yup.string().label("Learning Skill description").required(),
				})
			).label("Learning Skills").required().min(0),

			reportDates: yup.array().of(
				yup.object().shape({
					reportType: yup.string().label("Report Type").oneOf(reportTypes).required(),
					dueDate: yup.date().label("Due Date").required(),
					distributionDate: yup.date().label("Distribution Date").required().min(
						yup.ref('dueDate'),
						"Distribution date can't be before Due date"
					),
				})
			).label("Repert Dates").required()
		})
	})
)

export { createSemesterSchema, updateSemesterSchema };