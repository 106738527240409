
import { formatDistance } from "date-fns";
import { SemesterReportLayout, ReportDates } from "protobuffer-ts/dist/class_service/semester";
import { ReportType } from "../models/report_entry";
import { ObjectId } from "protobuffer-ts/dist/utils/object_id";


export class SemesterUpdate {
    id: ObjectId;
    archived: boolean;
    name: string;
    startDate: Date;
    endDate: Date;
    reportLayout: SemesterReportLayout;

    constructor(data: any) {
        this.id = data.id || data._id;  // Accept both `_id` and `id` in the constructor
        this.archived = data.archived;
        this.name = data.name;
        this.startDate = data.startDate || data.start_date;
        this.endDate = data.endDate || data.end_date;
        if (data.reportLayout || data.report_layout) {  // Handle both styles
            this.reportLayout = SemesterReportLayout.fromJSON(data.reportLayout || data.report_layout);
        } else {
            // Default values
            this.reportLayout = {
                commentCharLimit: 800,
                includeProgressReportCards: true,
                showCreditsEarnedEntry: false,
                learningSkills: [],
                reportDates: [
                    { reportType: ReportType.Progress },
                    { reportType: ReportType.Midterm },
                    { reportType: ReportType.Final },
                ],
            };
        }
    }
}


export interface CreateSemester {
    name: string;
    start_date: Date;
    end_date: Date;
}

export interface UpdateSemester {
    id: ObjectId;
    name: string;
    startDate: Date;
    endDate: Date;
    reportLayout: SemesterReportLayout;
}

export interface SemesterLearningSkill {
    id: ObjectId;
    title: string;
    description: string;
}

export const gerReportDate = (reportDates: ReportDates[] = [], reportType: ReportType | undefined, dateType: 'dueDate' | 'distributionDate'): string | undefined => {
    const date = reportDates.find(date => date.reportType.toString() === reportType)?.[dateType]

    if (date) return formatDistance(new Date(date), new Date(), { addSuffix: true})
};